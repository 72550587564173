
import { defineComponent } from "vue";
import BookableUser from "@/components/jobs/Booking/Misc/BookableUser.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { mapGetters } from "vuex";

type Pagination = {
  currentPage: number;
  totalPages: number;
};

export default defineComponent({
  name: "book-workers-datatable",
  data: () => ({
    pagination: {
      currentPage: 1,
      totalPages: 1
    } as Pagination
  }),
  components: {
    Pagination,
    BookableUser
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    selectedSkill: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", ["getPagination", "isFetchingUsers"])
  },
  mounted() {
    this.pagination.currentPage = this.getPagination.currentPage;
    this.pagination.totalPages = this.getPagination.lastPage;
  },
  updated() {
    this.pagination.currentPage = this.getPagination.currentPage;
    this.pagination.totalPages = this.getPagination.lastPage;
  }
});
