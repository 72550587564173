<template>
  <div class="modal fade" id="applicationDeleteModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="applicationDeleteModalLabel">Are you sure?</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <p v-if="applicantData">Delete {{ applicantData.assignee.name }}'s application ?</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Close
          </button>
          <button
            @click="deleteButtonClicked"
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "delete-application-modal",
  props: {
    applicantData: Object
  },
  methods: {
    deleteButtonClicked() {
      this.$emit("deleteApplication");
    }
  }
});
</script>
