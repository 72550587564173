
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import BookUserBox from "@/components/jobs/Booking/Misc/BookUserBox.vue";
import IconRightArrow from "@/components/Icons/IconRightArrow.vue";
import { payload } from "@/components/jobs/Booking/Misc/types";
import { useStore } from "vuex";

export default defineComponent({
  name: "change-user-skill-modal",
  components: {
    BookUserBox,
    IconRightArrow,
    LoadingSpinner
  },
  props: {
    payload: {
      type: Object as PropType<payload>,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const isSaving = ref<boolean>(false);
    const selectedSlot = ref<number | null>(null);
    const skillsUI = reactive({
      currentSkill: "",
      selectedSkill: ""
    });
    const skills = computed(() => store.getters["ListsModule/skills"]);
    const item = computed(() => store.getters["JobModule/item"]);
    const possibleSlots = computed(
      () => store.getters["JobModule/getPossibleWorkerSlots"]
    );
    watch(selectedSlot, () => {
      skillsUI.selectedSkill =
        possibleSlots.value.find(
          item => item.shift_slot_id == selectedSlot.value
        ).skill.name ?? "";
    });
    const setCurrentSkill = () => {
      if (skills.value) {
        const skillID = item.value.shifts
          .find(shift => shift.id == props.payload?.shiftID)
          .shift_slots.find(
            slot => slot.id == props.payload?.application.pivot.shift_slot_id
          ).skill_id;
        skillsUI.currentSkill = skills.value
          .map(item => item.children)
          .flat()
          .find(skill => skill.id == skillID)?.name;
      }
    };
    onMounted(() => {
      if (props.payload != null) {
        setCurrentSkill();
        isLoading.value = true;
        store
          .dispatch("JobModule/fetchPossibleSlots", props.payload)
          .finally(() => {
            isLoading.value = false;
          });
      }
    });
    const handleShiftLabel = computed<string>(() => {
      if (props.payload) {
        return item.value.shifts.find(
          shift => shift.id == props.payload?.shiftID
        ).label;
      }
      return "";
    });
    const handleSaveChanges = () => {
      isSaving.value = true;
      store
        .dispatch("JobModule/saveChangedSlot", {
          applicationID: props.payload?.application.id,
          shiftID: props.payload?.shiftID,
          newShiftSlot: selectedSlot.value
        })
        .then(() => {
          store
            .dispatch("JobModule/updateAfterSlotChange", item.value.id)
            .finally(() => {
              isSaving.value = false;
              emit("closeModal");
            });
        });
    };
    return {
      isLoading,
      isSaving,
      handleSaveChanges,
      skillsUI,
      handleShiftLabel,
      possibleSlots,
      selectedSlot
    };
  }
});
