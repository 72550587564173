<template>
  <el-empty
    v-if="applicationsInShiftSlot.length == 0"
    description="No booked users"
  ></el-empty>
  <div class="table-responsive mx-5 overflow-x" v-else>
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <thead class="fw-bolder">
        <tr>
          <th>Assignee</th>
          <th>Status</th>
          <th>Skill</th>
          <th>Assigned by</th>
          <th class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <BookedUser
          v-for="application in applicationsInShiftSlot"
          :key="application.id"
          :application="application"
          :shiftID="shiftID"
          @deleteModal="openDeleteModal"
        ></BookedUser>
      </tbody>
    </table>
  </div>
</template>

<script lang="js">
import {defineComponent, nextTick} from "vue";
import BookedUser from "@/components/jobs/Booking/Misc/BookedWorker.vue";
import ChangeUserSkillModal from "@/components/jobs/Booking/Misc/ChangeUserSkillModal.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "booked-workers-table",
  components: {
    BookedUser
  },
  data: () => ({
    modal: null,
    modalKey: 0,
    modalPayload: null,
    priorityMap: {
      "Report!": 2,
      "Booked": 2,
      "Reported": 3,
      "Applied": 3,
      "Request accepted": 4,
      "Approved": 5,
      "Requested": 5,
      "Paid": 6,
      "Reserve": 6,
      "Denied": 8,
      "Request denied": 9
    }
  }),
  props: {
    shiftSlots: {
      type: Array,
      required: true
    },
    shiftID: {
      type: Number,
      required: true
    }
  },
  methods: {
    openDeleteModal(application) {
      this.$emit('openDeleteModal', application);
    },
  },
  computed: {
    applicationsInShiftSlot() {
      const applications = this.shiftSlots.map(a => a.applications).flat();
      const getPriority = (slot) => {
        return this.priorityMap[slot.status.name] || 10;
      };
      applications.sort((a, b) => getPriority(a) - getPriority(b));
      return applications;
    }
  }
});
</script>

<style scoped>
@media all and (min-width: 1200px) {
  .overflow-x {
    overflow-x: visible;
  }
}
</style>