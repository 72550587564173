<template>
  <div>
    <div>
      <div class="d-flex align-items-center mb-2">
        <h4 class="m-0 p-0 me-3">
          Shift #{{ index + 1 }} - {{ shiftdata.label }}
        </h4>
        <div class="d-flex flex-wrap gap-3">
          <BookedCrewPill
            :key="index"
            v-for="(crewSlot, index) in shiftdata.crew.breakdown"
            :data="crewSlot.label"
            :percentage="crewSlot.progress"
          />
        </div>
      </div>
    </div>
    <div>
      <BookedWorkersTable
        @openDeleteModal="data => $emit('deleteModal', data)"
        :shiftID="shiftdata.id"
        :shiftSlots="shiftdata.shift_slots"
      ></BookedWorkersTable>
    </div>
    <hr />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BookedWorkersTable from "@/components/jobs/Booking/Tables/BookedWorkersTable.vue";
import BookedCrewPill from "@/components/general/BookedCrewPill.vue";

export default defineComponent({
  name: "booked-shift-slot",
  components: {
    BookedCrewPill,
    BookedWorkersTable
  },
  props: {
    shiftdata: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
});
</script>
<style scoped>
.lh-30 {
  line-height: 30px;
}
</style>
