import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "symbol symbol-45px me-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.userData.photo_url
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: `/users/${_ctx.userData.id}/personal`,
        class: "text-dark fw-bolder text-hover-primary fs-7"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.userData.name), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      (_ctx.userData.phone)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: `tel:${_ctx.userData.phone}`,
            class: "text-muted fw-bold text-muted d-block fs-7 text-hover-primary"
          }, _toDisplayString(_ctx.userData.phone), 9, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.userData.email)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: `mailto:${_ctx.userData.email}`,
            class: "text-muted fw-bold text-muted d-block fs-7 text-hover-primary"
          }, _toDisplayString(_ctx.userData.email), 9, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}