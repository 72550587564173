<template>
  <tr>
    <td>
      <BookUserBox :userData="user"></BookUserBox>
    </td>
    <td>
      <div class="d-flex flex-column justify-content-center">
        <span class="badge bg-success rounded-pill mb-2 width-fit-content">{{
          skillName
        }}</span>
        <span class="badge bg-primary rounded-pill mb-2 width-fit-content">{{
          skillLevel
        }}</span>
      </div>
    </td>
    <td class="fit-content">
      <div>
        <el-select
          :class="error ? 'border rounded-1 border-danger' : ''"
          v-model="selectedShifts"
          clearable
          size="large"
          filterable
          :placeholder="
            error ? 'Please select shifts before booking' : 'Select shifts'
          "
          multiple
        >
          <el-option
            v-for="shift in shifts"
            :key="shift.id"
            :label="shift.label"
            :value="shift.id"
          ></el-option>
        </el-select>
      </div>
    </td>
    <td class="text-end">
      <button
        :disabled="isAwaitingBook == user.id ? true : false"
        class="btn btn-success btn-sm"
        @click="bookButtonHandler(user.id)"
      >
        <div class="d-flex align-items-center">
          <span
            v-if="isAwaitingBook == user.id"
            class="spinner-border spinner-border-sm me-1 "
            role="status"
          ></span>
          Book
        </div>
      </button>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from "vue";
import BookUserBox from "@/components/jobs/Booking/Misc/BookUserBox.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "bookable-user",
  components: {
    BookUserBox
  },
  data: () => ({
    selectedShifts: [],
    error: false
  }),
  props: {
    user: {
      type: Object,
      required: true
    },
    selectedSkill: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item",
      shiftsWithSkill: "shiftsWithSkill",
      isAwaitingBook: "isAwaitingBook",
      successfullyBooked: "successfullyBooked"
    }),
    ...mapGetters("LevelsModule", ["getData"]),
    skillName() {
      return this.user.skills.filter(skill => skill.id == this.selectedSkill)[0]
        .name; //We allways get the result as an array with index 0 i dont think we need to use CPU power for flatteing all 10 results
    },
    skillLevel() {
      const skill = this.user.skills.filter(
        skill => skill.id == this.selectedSkill
      )[0];
      if (skill && !skill.skill_link) {
        return "Green skill";
      }
      const skillLevelID = skill.skill_link.level_id;
      if (skillLevelID) {
        const level = this.getData.find(level => level.id === skillLevelID);
        return level ? level.name : "N/A";
      } else {
        return "N/A";
      }
    },
    shifts() {
      const applicationsInShift = this.shiftsWithSkill
        .map(a => a.shift_slots)
        .flat()
        .map(a => a.applications)
        .flat();
      const filtered = applicationsInShift.filter(
        a => a.assignee.id == this.user.id && a.status === "Booked"
      );
      const bookedIn = [];
      for (let i = 0; i < filtered.length; i++) {
        const shiftSlotID = filtered[i].pivot.shift_slot_id;
        bookedIn.push(
          this.shiftsWithSkill
            .map(a => a.shift_slots)
            .flat()
            .find(shiftslot => shiftslot.id == shiftSlotID).shift_id
        );
      }
      const shifts = this.shiftsWithSkill.filter(a => !bookedIn.includes(a.id));
      return shifts;
    }
  },
  methods: {
    bookButtonHandler(userID) {
      if (this.selectedShifts.length !== 0) {
        this.$emit("book", { shifts: this.selectedShifts, user: userID });
      } else {
        this.error = true;
      }
    }
  },
  watch: {
    selectedShifts() {
      this.error = false;
    },
    successfullyBooked() {
      if (this.successfullyBooked == this.user.id) {
        this.selectedShifts = [];
      }
    }
  }
});
</script>

<style scoped>
.width-fit-content {
  width: fit-content;
}
</style>
