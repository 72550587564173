<template>
  <LoadingSpinner v-if="job && loadingLevels"></LoadingSpinner>
  <BookWorkersCard v-if="job && !loadingLevels" :job="job"></BookWorkersCard>
  <BoookedWorkersCard
    v-if="job && !loadingLevels"
    :job="job"
  ></BoookedWorkersCard>
</template>

<script lang="js">
import { defineComponent } from "vue";
import BookWorkersCard from "@/components/jobs/Booking/BookWorkersCard.vue";
import BoookedWorkersCard from "@/components/jobs/Booking/BookedWorkersCard.vue";
import { mapGetters, mapActions } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "book-workers-card",
  components: {
    LoadingSpinner,
    BookWorkersCard,
    BoookedWorkersCard
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item"
    }),
    ...mapGetters("LevelsModule", {
      //Levels are used to compute skill and level badges so we need to make sure we have the levels loaded
      loadingLevels: "loading"
    }), //because if job is here before levels due to request timing it will fail the rendering of the whole site
  },
  methods: {
    ...mapActions("LevelsModule", ["fetchData"]),
    ...mapActions("ListsModule", ["fetchSkills"])
  },
  beforeMount() {
    this.fetchData(); //For levels module
    this.fetchSkills();
  }
});
</script>
