<template>
  <div v-if="$can('shift_slot_change_status', 'all')">
    <div
      class="btn-group dropdown w-75"
      data-boundary="scrollParent"
      v-if="statusName !== 'Free'"
    >
      <button
        type="button"
        :disabled="isAwaitingResponse"
        :class="buttonClass"
        class="btn btn-success btn-sm dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-boundary="scrollParent"
      >
        <span
          v-if="isAwaitingResponse"
          class="spinner-border spinner-border-sm"
        ></span>
        {{ shiftStatusIfPast }}
      </button>
      <ul class="dropdown-menu" data-boundary="scrollParent">
        <li v-for="type in possibleShiftTypes" :key="type">
          <span
            class="dropdown-item"
            role="button"
            @click="changeJobStatus(type)"
            >{{ type }}</span
          >
        </li>
      </ul>
    </div>
    <span
      class="badge badge-light fw-bolder px-4 py-3 w-75"
      :class="statusName == 'Free' ? 'badge-light' : 'badge-warning'"
      v-else
      >{{ shiftStatusIfPast }}</span
    >
  </div>
  <span class="btn btn-sm w-75 pe-none" :class="buttonClass" v-else>{{
    shiftStatusIfPast
  }}</span>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { PastShiftStatuses, UpcomingShiftStatuses } from "@/core/helpers/shift_status.js";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default defineComponent({
  name: "shift-slot-button",
  props: {
    status: String,
    application: Object,
    shiftID: Number
  },
  data() {
    return{
      isAwaitingResponse: false,
      isShiftPast: null
    };
  },
  computed: {
    ...mapGetters("JobModule", {
        job : "item"
    }),
    possibleShiftTypes() {
      if (this.isShiftPast) {
        return PastShiftStatuses.filter(job => job !== this.shiftStatusIfPast);
      } else {
        return UpcomingShiftStatuses.filter(job => job !== this.shiftStatusIfPast);
      }
    },
    buttonClass() {
      switch (this.shiftStatusIfPast) {
        case "Report!":
          return "btn-danger";
        case "Approved":
          return "btn-cyan";
        case "Reported":
          return "btn-primary";
        case "Paid":
          return "btn-dark";
        case "Denied":
          return "btn-danger";
        case "Applied":
          return "btn-gray"
        case "Booked":
          return "btn-success";
        case "Requested":
          return "btn-purple"
        case "Request accepted":
          return "btn-info"
        case "Request denied":
          return "btn-lightpurple"
      }
      return "btn-warning";
    },
    shiftStatusIfPast() {
      if (this.isShiftPast && this.statusName == "Booked") {
        return "Report!";
      } else {
        return this.statusName;
      }
    },
    statusName() {
      if(this.status) {
        return this.status.name;
      }
      return "";
    }
  },
  mounted() {
     this.isShiftPast = this.job.shifts.find(shift => shift.id == this.shiftID).is_past;
  },
  methods: {
    ...mapActions("JobModule", ["changeBookedWorkerStatus", "updateShiftSlot"]),
    changeJobStatus(type) {
        const payload = {
            shift_slots: [this.application.pivot.shift_slot_id],
            status: type,
            created_by_id: this.application.created_by_id,
            assignee_id: this.application.assignee_id,
            check_in_timestamp: this.application.check_in_at ? this.application.check_in_at : null
        }
        this.isAwaitingResponse = true;
        this.changeBookedWorkerStatus({payload: payload, pivot:this.application.pivot})
          .then((response) => {
            this.updateShiftSlot({shiftID: this.shiftID, booked_by: response.data.booked_by, shiftSlotID: this.application.pivot.shift_slot_id, id: this.application.id, newStatus: response.data.status})
            this.isAwaitingResponse = false;
          })
          .catch((err) => {
            this.isAwaitingResponse = false;
            if(err.response) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1000
              });
            }
          })
    },
  }
});
</script>

<style scoped>
.btn-cyan {
  background-color: #0dcaf0;
}
.btn-gray {
  background-color: rgb(157, 156, 156);
}
.btn-purple {
  background-color: rgb(196, 16, 196);
}
.btn-lightpurple {
  background-color: #9370db;
}
</style>
