<template>
  <tr>
    <td>
      <BookUserBox :userData="application.assignee"></BookUserBox>
    </td>
    <td>
      <ShiftSlotStatusButton
        :status="application.status"
        :application="application"
        :shiftID="shiftID"
      ></ShiftSlotStatusButton>
    </td>
    <td>
      <div
        class="d-flex flex-column cursor-pointer"
        @click="handleChangeSkillModal"
      >
        <span class="badge badge-success rounded-pill mb-2 width-fit-content">{{
          userSkillName
        }}</span>
        <span class="badge badge-primary rounded-pill width-fit-content">{{
          userSkillLevel
        }}</span>
      </div>
    </td>
    <td>
      <BookUserBox
        v-if="application.booked_by"
        :userData="application.booked_by"
      ></BookUserBox>
    </td>
    <td class="text-end">
      <button
        :disabled="isAwaitingDelete == application.id ? true : false"
        class="btn btn-danger btn-sm"
        @click="$emit('deleteModal', application)"
        data-bs-toggle="modal"
        data-bs-target="#applicationDeleteModal"
      >
        <div class="d-flex align-items-center">
          <span
            v-if="isAwaitingDelete == application.id"
            class="spinner-border spinner-border-sm me-1 "
            role="status"
          ></span>
          Delete
        </div>
      </button>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from "vue";
import ShiftSlotStatusButton from "@/components/jobs/Booking/Misc/BookedShiftSlotStatusButton.vue";
import BookUserBox from "@/components/jobs/Booking/Misc/BookUserBox.vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "booked-worker",
  components: {
    ShiftSlotStatusButton,
    BookUserBox
  },
  data: () => ({
    modal: null
  }),
  props: {
    application: {
      type: Object,
      required: true
    },
    shiftID: {
      type: Number,
      required: true
    }
  },
  mounted() {
    if (this.skills.length === 0) {
      this.fetchSkills();
    }
  },
  methods: {
    ...mapActions("JobModule", ["setChangeSkillPayload"]),
    ...mapActions("ListsModule", ["fetchSkills"]),
    handleChangeSkillModal() {
      this.setChangeSkillPayload({
        application: this.application,
        shiftID: this.shiftID
      });
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item",
      isAwaitingDelete: "isAwaitingDelete"
    }),
    ...mapGetters("LevelsModule", ["getData"]),
    ...mapGetters("ListsModule", ["skills"]),
    userSkill() {
      const skillID = this.job.shifts //from shifts
        .find(shift => shift.id == this.shiftID) //find the application related shift
        .shift_slots.find(
          //find the associated skill slot
          slot => slot.id == this.application.pivot.shift_slot_id //and take its required skill
        ).skill_id;
      if (skillID) {
        let assigneeSkill = this.application.assignee.skills.find(
          skill => skill.id == skillID //and return the related skill in user
        );
        if (!assigneeSkill) {
          const skill = this.skills
            .map(skill => skill.children)
            .flat()
            .find(
              skill => skill.id == skillID //and return the related skill in user
            );
          console.log(this.skills.map(skill => skill.children));
          if (skill) {
            console.log("asdasd");
            assigneeSkill = skill;
          }
        }
        return assigneeSkill;
      } else {
        return false; //If due to some reason data is bad return false
      }
    },
    userSkillLevel() {
      let levelID = null;
      if (this.userSkill) {
        levelID = this.userSkill.skill_link
          ? this.userSkill.skill_link.level_id
          : null;
      }
      if (levelID !== null) {
        const level = this.getData.find(level => level.id === levelID);
        return level ? level.name : "N/A";
      } else {
        return "Green Skill";
      }
    },
    userSkillName() {
      if (this.userSkill) {
        return this.userSkill.name;
      } else {
        return "No skill data";
      }
    }
  }
});
</script>

<style scoped>
.width-fit-content {
  width: fit-content;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
