
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export interface BookingData {
  assigneeId: number;
  createdBy: number;
  skill: number;
  shifts: number[];
}

export interface MissingRatesData {
  jobId: number;
  errors: any | null;
}

export interface CompanyRate {
  id?: number | null;
  standard: number;
  weekend: number;
  night: number;
}

export interface WorkerRate {
  id?: number | null;
  standard: number;
  weekend: number;
  night: number;
  level: number;
}

export default defineComponent({
  name: "missing-rates-booking-modal",
  setup: function() {
    const store = useStore();
    const modal = ref<Modal | null>(null);
    const bookingData = ref<BookingData | null>(null);
    const missingRatesData = ref<MissingRatesData | null>(null);
    const loading = ref<boolean>(false);

    const companyRate = ref<CompanyRate | null>(null);
    const workerRate = ref<WorkerRate | null>(null);
    const skills = computed(() => store.getters["JobModule/getJobSkills"]);
    const levels = computed(() => store.getters["LevelsModule/getData"]);

    function resetModalState(): void {
      bookingData.value = null;
      companyRate.value = null;
      missingRatesData.value = null;
    }

    function initRate(type: string): void {
      if ("company" === type) {
        companyRate.value = {
          standard: 0,
          night: 0,
          weekend: 0
        };
      } else {
        workerRate.value = {
          standard: 0,
          night: 0,
          weekend: 0,
          level: 0
        };
      }
    }

    const skillName = computed<string>((): string => {
      if (bookingData.value) {
        const skill = skills.value.find(
          item => item.id == bookingData.value?.skill
        );
        if (skill) {
          return skill.name as string;
        }
      }
      return "";
    });

    const levelName = computed<string | null>(() => {
      const workerErrors = missingRatesData.value?.errors.worker;
      if (!workerErrors) {
        return null;
      }
      const levelId = workerErrors.level_id;
      if (!levelId) {
        return null;
      }
      const searchResult = levels.value.find(item => item.id === levelId);
      return searchResult ? (searchResult.name as string) : null;
    });

    function setModalState(
      userData: BookingData,
      missingRates: MissingRatesData
    ): void {
      bookingData.value = userData;
      missingRatesData.value = missingRates;
      const { errors } = missingRatesData.value || {};
      if (errors) {
        const errorKeys = Object.keys(errors);
        if (errorKeys.includes("company")) {
          initRate("company");
        }
        if (errorKeys.includes("worker")) {
          initRate("worker");
        }
      }
    }

    onBeforeMount(() => {
      if (levels.value.length == 0) {
        store.dispatch("ListsModule/fetchLevels");
      }
    });

    onMounted((): void => {
      const element = document.getElementById("missing-rates-modal-book");
      if (element) {
        modal.value = new Modal(element);
        element.addEventListener("hidden.bs.modal", () => {
          resetModalState();
        });
      }
    });

    function openModal(
      userData: BookingData,
      missingRates: MissingRatesData
    ): void {
      if (modal.value && userData && missingRates) {
        setModalState(userData, missingRates);
        modal.value.show();
      }
    }

    function bookUser(): void {
      loading.value = true;
      store.dispatch("JobModule/bookUser", bookingData.value).then(() => {
        if (modal.value) {
          modal.value.hide();
          loading.value = false;
        }
      });
    }

    function handleSaveRatesAndBook(): void {
      const hasBookingData = !!bookingData.value;
      const hasMissingRatesData = !!missingRatesData.value;

      if (hasBookingData && hasMissingRatesData) {
        loading.value = true;

        const createRatePayload = (
          rateValue: CompanyRate | WorkerRate,
          errorKey: string
        ): CompanyRate | WorkerRate | null => {
          if (rateValue) {
            const error = missingRatesData.value?.errors[errorKey];
            return {
              id: error ? error.id : null,
              ...rateValue
            };
          }
          return null;
        };

        const companyRatePayload = createRatePayload(
          companyRate.value as CompanyRate,
          "company"
        );
        const workerRatePayload = createRatePayload(
          workerRate.value as WorkerRate,
          "worker"
        );

        const payload = {
          companyRate: companyRatePayload,
          workerRate: workerRatePayload,
          skillId: bookingData.value?.skill,
          jobId: missingRatesData.value?.jobId
        };

        store
          .dispatch("JobModule/saveMissingRate", payload)
          .then(() => {
            bookUser();
          })
          .catch(() => {
            loading.value = false;
          });
      }
    }

    return {
      handleSaveRatesAndBook,
      companyRate,
      workerRate,
      openModal,
      levelName,
      skillName,
      bookUser,
      loading,
      levels
    };
  }
});
