
import { defineComponent, ref, PropType, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "booked-workers-card-header",
  props: {
    jobId: {
      type: [Number, null] as PropType<number | null>,
      default: () => null
    }
  },
  setup(props) {
    const crewStatus = ref<string | null>(null);
    const store = useStore();
    onMounted(() => {
      if (null != props.jobId) {
        store
          .dispatch("JobModule/getJobCrewStatus", props.jobId)
          .then(status => {
            crewStatus.value = status;
          })
          .catch(() => {
            crewStatus.value = null;
          });
      }
    });
    return {
      crewStatus
    };
  }
});
