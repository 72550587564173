
import { defineComponent } from "vue";

export default defineComponent({
  name: "book-user-box",
  props: {
    userData: {
      type: Object,
      required: true
    }
  }
});
