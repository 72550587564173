<template>
  <DeleteApplicationModal
    :applicantData="selectedApplication"
    @deleteApplication="handleDeleteApplication"
  ></DeleteApplicationModal>
  <ChangeUserSkillModal @closeModal="handleModalClose" :payload="modalPayload" :key="modalKey"></ChangeUserSkillModal>
  <div class="row" v-if="job">
    <div class="col-12 mt-5">
      <div class="card">
        <div class="card-header">
          <div class="card-title" v-if="job">
            <BookedWorkersCardHeader :job-id="job.id" />
          </div>
        </div>
        <div class="card-body p-9 pt-4" :key="renderKey">
          <Shift
            v-for="(shift, index) in job.shifts"
            :key="shift.id"
            :shiftdata="shift"
            :index="index"
            @deleteModal="handleOpenDeleteModal"
          ></Shift>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {defineComponent, nextTick} from "vue";
import Shift from "@/components/jobs/Booking/Shift.vue";
import DeleteApplicationModal from "@/components/jobs/Booking/Misc/DeleteApplicationModal.vue"
import ChangeUserSkillModal from "@/components/jobs/Booking/Misc/ChangeUserSkillModal";
import { mapGetters, mapActions } from "vuex";
import { Modal } from "bootstrap"
import BookedWorkersCardHeader from "@/components/jobs/Booking/Misc/BookedWorkersCardHeader.vue";

export default defineComponent({
  name: "booked-workers-card",
  components: {
    BookedWorkersCardHeader,
    ChangeUserSkillModal,
    DeleteApplicationModal,
    Shift
  },
  data: () => ({
    selectedApplication: null,
    modalKey: 0,
    modalPayload: null,
    renderKey: 0,
  }),
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions("JobModule", ["deleteApplication"]),
    handleOpenDeleteModal(application) {
      this.selectedApplication = application;
    },
    handleModalClose() {
      this.modal.hide();
      this.modalPayload = null;
      this.modalKey++;
      this.modal = null;
      this.renderKey++;
    },
    handleDeleteApplication() {
      this.deleteApplication(this.selectedApplication.id);
    }
  },
  watch: {
    async "changeSkillPayload"() {
      this.modalPayload = this.changeSkillPayload;
      this.modalKey++;
      await nextTick().then(() => {
        this.modal = new Modal(document.getElementById("change-skill-modal"));
        this.modal.show();
      });
    }
  },
  computed: {
    ...mapGetters("JobModule", [
        "changeSkillPayload"
    ])
  }
});
</script>
