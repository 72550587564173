<template>
  <MissingRatesBookingModal ref="missing_rates_modal" />
  <div class="row mt-8">
    <div class="col-12">
      <div class="card ">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="fw-bolder">Book workers</h3>
          </div>
          <div class="d-flex">
            <SearchField
              class="me-8"
              @changeSearch="handleSearchByName"
              :placeholder="'Search by worker`s name'"
            ></SearchField>
            <el-select
              filterable
              size="large"
              placeholder="Select skill"
              v-model="selectedSkill"
              @change="handleSearchBySkill"
            >
              <el-option
                v-for="item in getJobSkills"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="card-body p-9 pt-4">
          <LoadingSpinner v-if="isFetchingUsers"></LoadingSpinner>
          <BookWorkersTable
            :key="key"
            v-else
            :selectedSkill="selectedSkill"
            @bookUser="handleBookUser"
            @changePage="handleChangeCurrentPage"
            :users="bookableUsers"
          ></BookWorkersTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import SearchField from "@/components/general/SearchField.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import BookWorkersTable from "@/components/jobs/Booking/Tables/BookWorkersTable.vue";
import MissingRatesBookingModal from "@/components/jobs/Booking/Misc/MissingRatesBookingModal.vue";
import Swal from "sweetalert2";
import { fireToast } from "@/core/helpers/swal";

export default defineComponent({
  name: "book-workers-card",
  components: {
    MissingRatesBookingModal,
    LoadingSpinner,
    SearchField,
    BookWorkersTable
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    query: {},
    selectedSkill: NaN,
    key: 0,
  }),
  computed: {
    ...mapGetters("JobModule", [
      "item",
      "bookableUsers",
      "getJobSkills",
      "isFetchingUsers"
    ]),
    ...mapGetters({
      currentUser: "currentUser"
    })
  },
  watch: {
    job: {
      deep: true,
      handler() {
        this.key++;
      }
    }
  },
  methods: {
    ...mapActions("JobModule", [
      "fetchBookableUsers",
      "changeCurrentPage",
      "setShiftsWithSkill",
      "bookUser",
      "checkIsRateEmpty"
    ]),
    handleSearchByName(searchString) {
      this.changeCurrentPage(1);
      this.query.search = searchString;
      this.fetchBookableUsers(this.query);
    },
    handleChangeCurrentPage(pageNumber) {
      this.changeCurrentPage(pageNumber);
      this.fetchBookableUsers(this.query);
    },
    handleSearchBySkill() {
      this.query.skill = this.selectedSkill;
      this.fetchBookableUsers(this.query);
      this.setShiftsWithSkill(this.selectedSkill)
    },
    handleBookUser(data) {
      const payload = {
        assigneeId: data.user,
        shifts: data.shifts,
        skill: this.selectedSkill,
        createdBy: this.currentUser.id
      };
      if(this.$can("can_add_missing_rates", "all")) {
        this.checkIsRateEmpty({jobId: this.job.id, skillId: this.selectedSkill, assigneeId: data.user})
          .then(() => {
            this.bookUser(payload)
             .catch((err) => {
                if(err.data && err.data.type == "ERR.USER_BUSY") {
                  this.handleBookAnyway(payload, err);
                }
             });
          }).catch((err) => {
            const data = err.data;
            if (data.errors) {
              this.$refs.missing_rates_modal.openModal(payload, { jobId: this.job.id, errors: data.errors });
            }
          })
      } else {
        this.bookUser(payload)
          .catch((err) => {
            if(err.data && err.data.type == "ERR.USER_BUSY") {
              this.handleBookAnyway(payload, err);
            }
          });
      }
    },
    handleBookAnyway(payload, err) {
      console.log(err);
      Swal.fire({
        title: err.data.message,
        showCancelButton: true,
        icon: "error",
        confirmButtonText: "Book anyway",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            // eslint-disable-next-line @typescript-eslint/camelcase
            return await this.bookUser({ ...payload, book_anyway: true });
          } catch (error) {
            Swal.showValidationMessage(`
              An error occurred
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Application added successfully`,
            showConfirmButton: false,
            timer: 1000
          })
        }
      });
    }
  },
  beforeMount() {
    this.selectedSkill = this.getJobSkills[0].id; //First skill is taken by default in old front
    this.query = {
      bookFromBranches: this.job.book_from_branches.map(
        (a) => a.id
      ),
      search: "",
      skill: this.selectedSkill
    };
    this.fetchBookableUsers(this.query);
    this.setShiftsWithSkill(this.selectedSkill);
  }
});
</script>
